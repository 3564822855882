import { KnockFeedProvider } from '@knocklabs/react-notification-feed'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { useActivityKnockUserTokenDetails } from '@/activity/queries/useActivityKnockUserTokenDetails'
import { useAuth } from '@/auth/hooks/useAuth'
import { isManagerPageSelector } from '@/company/selectors'
import { env } from '@/env'

import { idSelector } from '../../../selectors'

const tenants = {
  COMPANY_SPACE: 'company-space',
  LEARNING_SPACE: 'learning-space',
} as const

interface NotificationsProps {
  children: ReactNode
}

export function NotificationsProvider({ children }: NotificationsProps) {
  const authUser = useAuth()
  const isManagerPage = useSelector(isManagerPageSelector)
  const userId = useSelector(idSelector)
  const { data } = useActivityKnockUserTokenDetails({
    enabled: authUser.isAuthenticated === true,
  })

  if (!data) {
    return children
  }

  const {
    REACT_APP_KNOCK_PUBLIC_API_KEY = 'default_key',
    REACT_APP_KNOCK_FEED_ID = 'default_id',
  } = env

  return (
    <KnockFeedProvider
      userId={userId}
      feedId={REACT_APP_KNOCK_FEED_ID}
      apiKey={REACT_APP_KNOCK_PUBLIC_API_KEY}
      userToken={data.knock_user_token}
      defaultFeedOptions={{
        tenant: isManagerPage ? tenants.COMPANY_SPACE : tenants.LEARNING_SPACE,
        page_size: 15,
        archived: 'include',
      }}>
      <>{children}</>
    </KnockFeedProvider>
  )
}
