import { isEmpty, keys, omit } from 'lodash/fp'
import { handleActions } from 'redux-actions'

import actions from '../actions/mediaSummary.actions'
import { MediaListRow } from '../types/MediaListRow'
import { MediaSummaryState } from '../types/MediaSummaryState'

const initialState: MediaSummaryState = {
  pageIndex: 0,
  pageSize: 10,
  pageSorting: [],
  filters: {},
  detailsAnimationMediaId: '',
  dialog: null,
  mediaId: undefined,
  selectedRows: {},
  showOnlySelected: false,
  tab: undefined,
}

export default handleActions<MediaSummaryState, any>(
  {
    [actions.setPageIndex]: (state, { payload }) => ({
      ...state,
      pageIndex: payload,
    }),
    [actions.setPageSize]: (state, { payload }) => ({
      ...state,
      pageSize: payload,
    }),
    [actions.setPageSorting]: (state, { payload }) => ({
      ...state,
      pageSorting: payload,
    }),
    [actions.setFilters]: (state, { payload }) => ({
      ...state,
      filters: payload,
    }),
    [actions.setDetailsAnimationMediaId]: (state, { payload }) => ({
      ...state,
      detailsAnimationMediaId: payload,
    }),
    [actions.setDialog]: (state, { payload }) => ({
      ...state,
      dialog: { mediaIds: keys(state.selectedRows), ...payload },
    }),
    [actions.setMediaId]: (state, { payload }) => ({
      ...state,
      mediaId: payload,
    }),
    [actions.clearSelectedRows]: (state) => ({
      ...state,
      selectedRows: {},
    }),
    [actions.setShowOnlySelected]: (state, { payload }) => ({
      ...state,
      showOnlySelected: payload,
    }),
    [actions.setTab]: (state, { payload }) => ({
      ...state,
      tab: payload,
    }),
    [actions.removeRowSelection]: (
      state: MediaSummaryState,
      { payload: { mediaId } }: { payload: { mediaId: string } },
    ) => {
      const selectedRows = omit([mediaId], state.selectedRows)

      // Safe measures to show whole table if no row is selected anymore
      // (otherwise we would see an empty table and no way to change that).
      if (isEmpty(selectedRows)) {
        return {
          ...state,
          selectedRows: {},
          showOnlySelected: false,
        }
      }

      return { ...state, selectedRows }
    },
    [actions.toggleRowSelection]: (
      state: MediaSummaryState,
      {
        payload: { mediaId, media },
      }: { payload: { mediaId: string; media: MediaListRow } },
    ) => {
      const selectedRows = state.selectedRows[mediaId]
        ? omit([mediaId], state.selectedRows)
        : { ...state.selectedRows, [mediaId]: media }

      // Safe measures to show whole table if no row is selected anymore
      // (otherwise we would see an empty table and no way to change that).
      if (isEmpty(selectedRows)) {
        return {
          ...state,
          selectedRows: {},
          showOnlySelected: false,
        }
      }

      return { ...state, selectedRows }
    },
    [actions.toggleShowOnlySelected]: (state) => ({
      ...state,
      showOnlySelected: !state.showOnlySelected,
    }),
    [actions.reset]: (_, { payload }) => ({
      ...initialState,
      ...payload,
    }),
  },
  initialState,
)
